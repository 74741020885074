import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, Stack, dialogClasses } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";

import { Avatar, Button, ButtonOutline, Text } from "components";
import { ImageEditor } from "components/Feature/ImageEditor";
import GroupTextField from "components/UI/Form/Input/GroupTextField";
import { TextArea } from "components/UI/Form/Input/TextArea";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import { TeamMember } from "models/organisation.model";
import { globalActions } from "store/global/global.slice";
import { selectOrganisationLoading } from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import styled from "styled-components/macro";
import { uploadFromBlobAsync } from "utils/firebase.util";

interface Props {
  data: TeamMember | null;
  organisationId?: string;
  isOpen: boolean;
  newMemberOrder?: number;
  hideFooter?: boolean;
  titleType?: "Add" | "Update" | "View";
  isOnlyView?: boolean;
  onClose: () => void;
}

const AddTeamMemberModal = ({
  organisationId,
  data,
  isOpen,
  newMemberOrder,
  hideFooter,
  titleType = "Add",
  isOnlyView,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const submitLoading = useAppSelector(selectOrganisationLoading);
  const [avatarImage, setAvatarImage] = useState<Blob | undefined>(undefined);

  const schema = yup.object().shape({
    photoUrl: yup.string(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    role: yup.string().required(),
    bio: yup.string(),
    email: yup.string().email().required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddMember = async (formData: FieldValues) => {
    if (!organisationId) return;

    const { firstName, lastName, ...form } = formData;
    const displayName = `${firstName} ${lastName}`;

    dispatch(
      organisationActions.addTeamMember({
        organisationId,
        member: {
          data: {
            ..._.omitBy(form, _.isEmpty),
            displayName,
            order: newMemberOrder,
          },
        },
        avatarImage,
        callback: () => handleClose(),
      })
    );
  };

  const handleUpdateMember = async (formData: FieldValues) => {
    if (!data || !organisationId) return;

    if (avatarImage) {
      try {
        dispatch(globalActions.setPageLoading(true));

        const url = await uploadFromBlobAsync({
          blob: avatarImage,
          name: "avatar",
          folder: `workspaces/${organisationId}/teams/${data.id}`,
        });

        setValue("photoUrl", url);
        dispatch(globalActions.setPageLoading(false));
      } catch (_) {
        dispatch(globalActions.setPageLoading(false));
      }
    }

    const { firstName, lastName, ...form } = formData;
    const displayName = `${firstName} ${lastName}`;

    dispatch(
      organisationActions.updateOrganisation({
        organisationId,
        input: {
          teamMembers: [
            {
              id: data.id,
              task: "UPDATE",
              data: {
                ..._.omitBy(form, _.isEmpty),
                displayName,
              },
            },
          ],
        },
        callback: () => handleClose(),
      })
    );
  };

  const handleClose = () => {
    onClose();
    reset();
    setAvatarImage(undefined);
  };

  useEffect(() => {
    if (data) {
      Object.keys(data).map((key) => {
        if (key === "id") return;

        if (key === "displayName") {
          const splitName = data[key].split(" ");

          setValue("firstName", splitName[0]);
          setValue("lastName", splitName[1]);
        } else {
          setValue(key, data[key as keyof TeamMember] || "");
        }
      });
    } else {
      reset();
    }
  }, [data, reset, setValue]);

  useEffect(() => {
    if (avatarImage) {
      setValue("photoUrl", "pending");
    }
  }, [avatarImage, setValue]);

  return (
    <BaseDialog
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      maxWidth="lg"
    >
      <HeaderBox>Team member {titleType.toLowerCase()}</HeaderBox>
      <Wrapper
        id="team-member-form"
        onSubmit={handleSubmit(data ? handleUpdateMember : handleAddMember)}
      >
        {isOnlyView ? (
          <Stack direction="row" gap="12px" alignItems="center">
            <Avatar src={data?.photoUrl} name={data?.displayName} size={64} />

            <Stack>
              <Text size="lg">{data?.displayName}</Text>
              <Text size="md">{data?.role}</Text>
            </Stack>
          </Stack>
        ) : (
          <GroupBox>
            <FieldWrapper>
              <Text size="md" fontWeight={600}>
                Attachment
              </Text>

              <ImageEditor
                className="avatar-editor"
                onSave={setAvatarImage}
                url={data?.photoUrl || ""}
                uploadText="Upload a SVG, PNG, JPG or GIF picture."
                error={errors.photoUrl?.message as string}
              />
            </FieldWrapper>

            <HorizontalBox>
              <GroupTextField
                fieldName="First name"
                placeholder="Fill first name"
                name="firstName"
                formRef={register}
                error={errors.firstName?.message as string}
                disabled={isOnlyView}
              />

              <GroupTextField
                fieldName="Last name"
                placeholder="Fill last name"
                name="lastName"
                formRef={register}
                error={errors.lastName?.message as string}
                disabled={isOnlyView}
              />
            </HorizontalBox>
          </GroupBox>
        )}

        {isOnlyView ? (
          <>
            <Text size="md">{data?.bio}</Text>
            {data?.email && (
              <Text size="md">
                <span className="text-bold">Email:</span> {data?.email}
              </Text>
            )}
          </>
        ) : (
          <>
            <FieldWrapper>
              <GroupTextField
                fieldName="Role"
                placeholder="Fill position in the company"
                name="role"
                formRef={register}
                error={errors.role?.message as string}
                disabled={isOnlyView}
              />
            </FieldWrapper>

            <FieldWrapper>
              <TextArea
                fieldName="Bio"
                name="bio"
                placeholder="Type some words here..."
                minRows={2}
                maxLength={1400}
                hasCount
                formRef={register}
                watch={watch}
                error={errors.bio?.message as string}
                disabled={isOnlyView}
              />
            </FieldWrapper>

            <FieldWrapper>
              <GroupTextField
                fieldName="Email"
                placeholder="example@gmail.com"
                name="email"
                formRef={register}
                error={errors.email?.message as string}
                disabled={isOnlyView}
              />
            </FieldWrapper>
          </>
        )}
      </Wrapper>

      {!hideFooter && (
        <FooterBox>
          <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>

          <Button form="team-member-form" type="submit" loading={submitLoading}>
            {data ? "Update member" : "Add member"}
          </Button>
        </FooterBox>
      )}
    </BaseDialog>
  );
};

export default AddTeamMemberModal;

const BaseDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const HeaderBox = styled.div`
  margin: 32px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ebecef;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 24px;
  font-weight: 700;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 520px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
  gap: 25px;

  .avatar-editor {
    width: 142px;
    height: 142px;

    &.error {
      border-color: #eb5a46;
    }
  }

  .text-bold {
    font-weight: 600;
  }

  .${dialogClasses.paper} {
    border-radius: 32px;
    background-color: red;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: unset;
    min-width: calc(100vw - 64px);
  }
`;

const GroupBox = styled.div`
  display: flex;
  gap: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const HorizontalBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
`;

const FooterBox = styled.div`
  display: flex;
  gap: 16px;
  margin: 32px 32px 0px;
  padding: 32px 0px;
  border-top: 1px solid #ebecef;

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }

  & button,
  .button-wrapper {
    width: 100%;
  }
`;
