import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PageTitle } from "components";
import ConfirmModal from "components/Feature/ConfirmModal";
import { PATH } from "constants/app.const";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import {
  selectOrganisation,
  selectOrganisationProgress,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { MFForm } from "./MFForm";

export const MFTrustScore = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organisationProgress = useAppSelector(selectOrganisationProgress);
  const { mfScore } = useAppSelector(selectOrganisation);
  const isCompleteSettings = !organisationProgress.find(
    (item) => !item.isPassed
  );

  useEffect(() => {
    if (!isCompleteSettings) {
      setIsOpenModal(true);
    }
  }, [isCompleteSettings]);

  useEffect(() => {
    dispatch(organisationActions.getCompanyOrganisation());
    dispatch(organisationActions.getTrustScoreQuestions());
  }, [dispatch]);

  useEffect(() => {
    if (mfScore) {
      navigate(PATH.COMPANY_PREVIEW);
    }
  }, [mfScore, navigate]);

  return (
    <Wrapper>
      <PageTitle className="title">MF Trust Score</PageTitle>

      <MFForm />

      <ConfirmModal
        title="Fill out Company Settings"
        subTitle="Fill out Сompany Settings to get your MF Trust Score"
        confirmButtonText="Complete Settings"
        isOpen={isOpenModal}
        loading={false}
        onClose={() => setIsOpenModal(false)}
        callback={() => navigate(PATH.COMPANY_SETTINGS)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;
  padding: 32px;

  .title {
    font-size: 28px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 20px;
  }
`;
