import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { StatusIndicator } from "models/common.model";
import {
  getLatestFundraising,
  hasValue,
  reorderTeamMembers,
} from "utils/system.util";
import { initialState, OrganisationState } from "./organisation.slice";

const selectDomain = (state: { organisation: OrganisationState }) =>
  state.organisation || initialState;

export const selectOrganisationLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectOrganisations = createSelector([selectDomain], (state) =>
  _.orderBy(state.organisations, ["updatedAt"], ["desc"])
);

export const selectOrganisation = createSelector([selectDomain], (state) => {
  const { acceptMA: rawAcceptMA } = state.selectedOrganisation;
  const acceptMA = _.isBoolean(rawAcceptMA)
    ? rawAcceptMA
    : getLatestFundraising(state?.selectedOrganisation?.fundraisingProjects)
        ?.acceptMA;

  return {
    ...state.selectedOrganisation,
    acceptMA,
    teamMembers: reorderTeamMembers(state.selectedOrganisation.teamMembers),
  };
});

export const selectPossibleEditOrganisation = createSelector(
  [selectOrganisation],
  ({ status }) =>
    !_.includes(
      [
        StatusIndicator.REVIEW,
        StatusIndicator.PUBLISHED,
        StatusIndicator.ARCHIVED,
      ],
      status as StatusIndicator
    )
);

export const selectOrganisationProgress = createSelector(
  [selectOrganisation],
  (state) => {
    if (!state.id) return [];

    const {
      posterUrl,
      logoUrl,
      displayName,
      description,
      websiteUrl,
      videoUrl,
      sector,
      impactValues,
      businessType,
      productType,
      annualRevenue,
      MRR,
      country,
      city,
      zip,
      address,
      slogan,
      acceptMA,
      teamMembers,
    } = state;

    return [
      { label: "Logo", isComplete: hasValue(logoUrl), isPassed: true },
      { label: "Cover", isComplete: hasValue(posterUrl), isPassed: true },
      {
        label: "My Company",
        isComplete: hasValue(displayName) && hasValue(slogan),
        isPassed: hasValue(displayName),
      },
      {
        label: "About",
        isComplete:
          hasValue(description) && hasValue(websiteUrl) && hasValue(videoUrl),
        isPassed: hasValue(description),
      },
      {
        label: "Sectors",
        isComplete: hasValue(sector),
        isPassed: hasValue(sector),
      },
      {
        label: "Impacts",
        isComplete: hasValue(impactValues),
        isPassed: hasValue(impactValues),
      },
      {
        label: "Business",
        isComplete:
          hasValue(businessType) &&
          hasValue(productType) &&
          hasValue(annualRevenue) &&
          hasValue(MRR),
        isPassed: hasValue(businessType) && hasValue(productType),
      },
      {
        label: "Location",
        isComplete:
          hasValue(country) &&
          hasValue(city) &&
          hasValue(zip) &&
          hasValue(address),
        isPassed: hasValue(country) && hasValue(city),
      },
      {
        label: "Additional Info",
        isComplete: hasValue(acceptMA),
        isPassed: hasValue(acceptMA),
      },
      {
        label: "Team",
        isComplete: hasValue(teamMembers),
        isPassed: hasValue(teamMembers),
      },
    ];
  }
);

export const selectTrustScoreQuestions = createSelector(
  [selectDomain],
  (state) => state.trustScoreQuestions
);

export const selectFounderAssesment = createSelector(
  [selectDomain],
  (state) => state.founderAssesment
);
