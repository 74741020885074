import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import styled from "styled-components";

import { FormProgress, FormSlice } from "components";
import ConfirmModal from "components/Feature/ConfirmModal";
import { useAppDispatch, useAppSelector } from "hooks/useHook";
import {
  FounderScoreQuestion,
  FounderScoreResult,
  FounderSubmitAnswer,
} from "models/organisation.model";
import {
  selectFounderAssesment,
  selectOrganisationLoading,
  selectOrganisationProgress,
} from "store/organisation/organisation.selector";
import { organisationActions } from "store/organisation/organisation.slice";
import { ThankYouScreen, WelcomeScreen } from "./components";

export const FSForm = () => {
  const [step, setStep] = useState(0);
  const [scoreResult, setScoreResult] = useState<FounderScoreResult[]>([]);
  const [answerCount, setAnswerCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const assesment = useAppSelector(selectFounderAssesment);
  const { items: questionsRaw } = assesment;
  const submitLoading = useAppSelector(selectOrganisationLoading);
  const organisationProgress = useAppSelector(selectOrganisationProgress);

  const isCompleteSettings = !organisationProgress.find(
    (item) => !item.isPassed
  );

  const questions = questionsRaw.map((item: FounderScoreQuestion) => {
    const { choices, text } = item;
    const options = choices.map((choice) => ({
      label: choice.text,
      value: choice.score,
    }));
    return { ...item, options, question: text };
  });

  const showProgress = step > 0 && step < questions.length + 1;

  const { setValue, getValues, handleSubmit } = useForm();

  const onSubmit = (data: FieldValues) => {
    const { scale_id, rating_scale } = assesment;

    const answers = questions.map((item) => {
      const { id, domain_id, rating_scale } =
        item as unknown as FounderScoreQuestion;
      return {
        id,
        score: data[id] || 1,
        domain_id,
        rating_scale,
      } as FounderSubmitAnswer;
    });

    dispatch(
      organisationActions.submitFounderScore({
        assesment: {
          answers,
          scale_id,
          rating_scale,
        },
        callback: (data) => {
          data && setScoreResult(data);
          setStep((step) => step + 1);
        },
      })
    );
  };

  const handleNext = (newStep: number) => {
    if (step === questions.length) {
      handleSubmit(onSubmit)();
    } else {
      setStep(newStep);
    }
  };

  const handleSelect = (id: string, value: number) => {
    setValue(id, value);
    setAnswerCount(Object.keys(getValues()).length);
  };

  return (
    <Wrapper step={step}>
      <WelcomeScreen
        disabled={!isCompleteSettings}
        onNext={() => setIsOpen(true)}
      />

      {questions.map((item, index) => (
        <FormSlice
          key={item.id}
          ordinalNumber={index + 1}
          data={item}
          isSubmit={step === questions.length}
          submitLoading={submitLoading}
          onSelect={(value) => handleSelect(item.id, value)}
          onNext={() => handleNext(index + 2)}
        />
      ))}

      <ThankYouScreen scoreResult={scoreResult} />

      {showProgress && (
        <FormProgress
          step={step}
          count={questions.length}
          completePercent={Math.round((answerCount / questions.length) * 100)}
          onChangeStep={(newStep) => setStep(newStep)}
        />
      )}

      <ConfirmModal
        title="Get Founder Score"
        subTitle="Please, note that you can submit the Founder score only once. Make sure to review all the answers before submitting."
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        callback={() => {
          setStep(1);
          setIsOpen(false);
        }}
        confirmButtonText="Start now"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #f2f0ea;
  overflow: hidden;

  & > .form-item {
    transition: all 0.5s;
    transform: ${({ step }: { step: number }) => `translateY(-${step * 100}%)`};
  }
`;
