import styled from "styled-components";

import { LoadingIcon, SuccessIcon } from "assets/images/common";
import { Progress } from "models/common.model";
import { NoticeTooltip } from "./NoticeTooltip";

interface Props {
  progressList: Progress[];
  tooltipText?: string;
}

export const ProgressList = ({ progressList, tooltipText }: Props) => {
  const completedList = progressList.filter((item) => item.isPassed);

  return (
    <Wrapper>
      <Overview className="overview">
        <Text>
          Completed sections:{" "}
          <StrongText>
            {completedList.length}/{progressList.length}
          </StrongText>
        </Text>

        <NoticeTooltip
          placement="top-start"
          data={{
            content: tooltipText,
          }}
        />
      </Overview>

      <ListWrapper>
        {progressList.map((item, index) => (
          <ListItem
            key={index}
            hasClick={!!item.onClick}
            onClick={item.onClick}
          >
            {item.isComplete ? (
              <SuccessIcon />
            ) : item.isPassed ? (
              <SuccessIcon color={"#c9af7f"} />
            ) : (
              <LoadingIcon />
            )}

            {item.isComplete ? (
              <StrongText>{item.label}</StrongText>
            ) : (
              <Text>{item.label}</Text>
            )}
          </ListItem>
        ))}
      </ListWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px 0px;
`;

const Overview = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.palette.primaryText.light};

  color: ${(props) => props.theme.palette.primaryText.main};
  font-weight: 600;
`;

const StrongText = styled.span`
  color: ${(props) => props.theme.palette.primaryText.main};
  font-weight: 600;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  transition: all 0.5s;
  cursor: ${({ hasClick }: { hasClick?: boolean }) => hasClick && "pointer"};

  &:hover {
    text-decoration: ${({ hasClick }: { hasClick?: boolean }) =>
      hasClick && "underline"};
  }

  svg {
    width: 17px;
    height: 17px;
  }
`;
